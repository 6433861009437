.selector-text {
  width: 344px;
  height: 23px;

  font-weight: 300;
  font-size: 16px;
  line-height: 145%;
  margin-left: 18px;
}

.selector {
  display: flex;
  align-items: left;
  width: 384px;
  height: 48px;
}

.selector-check {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

.selector-check img {
  pointer-events: none;
  height: 24px;
  width: 24px;
}

.small-selector-check img {
  pointer-events: none;
  height: 18px;
  width: 18px;
}
