.vendor-card {
  display: inline-grid;
  position: relative;
  width: 280px;
  height: 283px;
}

.frame-3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  position: absolute;
  width: 280px;
  height: 314px;
  left: 0px;
  top: 0px;
}

.location-label {
  width: 280px;
  height: 20px;
  opacity: 0.75;


  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 145%;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.title-label {
  width: 280px;
  height: 31px;


  font-weight: 500;
  font-size: 24px;
  line-height: 130%;

  letter-spacing: -0.3px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.card-image {
  width: 280px;
  height: 187px;

  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.category-label-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 8px;
  gap: 10px;

  position: absolute;

  height: 25px;
  left: 8px;
  top: 8px;

  background: #ffffff;
  border-radius: 4px;
}

.category-label-text {
  height: 17px;

  font-weight: 500;
  font-size: 14px;
  line-height: 120%;

  letter-spacing: -0.1px;


  flex: none;
  order: 0;
  flex-grow: 0;
}

.price-label {
  width: 280px;
  height: 23px;

  font-weight: 300;
  font-size: 16px;
  line-height: 145%;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.frame-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;

  width: 280px;
  height: 84px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.frame-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 280px;
  height: 84px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.frame-4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;

  width: 280px;
  height: 84px;


  flex: none;
  order: 0;
  flex-grow: 0;
}
