.left-column-container {
  position: absolute;
  height: 1516px;
  left: 0%;
  right: 77.78%;
  top: 64px;
  width: 320px;
  padding-left: 40px;
}

.listing {
  width: 255px;
  height: 26px;
  left: calc(50% - 255px / 2 + 7.5px);
  top: 64px;


  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 145%;

  letter-spacing: -0.15px;
  margin-left: 40px;
  margin-top: 64px;


  color: #15284c;
}

.main-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 320px;
  height: 64px !important;
}

.open-main-item {
  display: flex;
  padding: 0px;

  width: 320px;
  height: 64px !important;
}

.left-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 320px;
  height: 100%;
  left: 0px;
  top: 98px;
}

.main-item-label {
  width: 199px;
  height: 26px;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 145%;
  cursor: pointer;
}

.main-category-open {
  transform: rotate(180deg);
  transition: transform 0.2s;
  cursor: pointer;
}

.main-category-closed {
  transition: transform 0.2s;
  cursor: pointer;
}

.sub-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  position: relative;
  width: 320px;
  height: 48px !important;
}

.sub-item-inner-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 296px;
  height: 24px;
}

.sub-item-label {
  width: 200px;
  height: 23px;
  margin-top: 16px;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  margin-left: 80px;

  letter-spacing: -0.1px;


  color: #15284c;
  cursor: pointer;
}

.current {
  background-color: white;
}

.left-column-exit-button {
  position: absolute;
  width: 33px;
  height: 20px;
  left: 36px;
  top: calc(50% - 20px / 2 - 732px);


  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 145%;

  display: flex;
  align-items: center;
}

a {
  color: #15284c;
}

.main-item-inner-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 15px;

  position: relative;
  width: 240px;
  height: 26px;
  left: 40px;
  top: calc(50% - 26px / 2);
}

.main-item-image {
  position: relative;
  width: 24px;
  height: 24px;
  right: -25px;
  top: calc(50% - 24px / 2);

  opacity: 0.6;
}
