.form-container {
  position: absolute;
  top: 64px;
  left: 0%;
  right: 0%;
  bottom: 0%;
}

.signup-h4 {
  position: relative;
  height: 76px;
  width: 384px;

  left: 36.67%;
  right: 36.67%;
  top: 64px;


  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;

  letter-spacing: -0.7px;
}

.frame-6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  position: relative;
  width: 384px;
  height: 674px;
  left: 528px;
  top: 104px;
  transition: 0.1s linear;
}

.slide-left {
  transform: translateX(-125%);
  transition: 0.1s linear;
}

.slide-right {
  transform: translateX(200%);
  transition: 0.1s linear;
}

.group-9 {
  position: absolute;
  width: 384px;
  height: 674px;
  transition: 0.15s;
}


.frame-5 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  gap: 24px;

  width: 384px;
  height: 616px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.group-10 {
  position: absolute;
  width: 384px;
  height: 1011px;
}

.subtitle-1 {
  width: 384px;
  height: 26px;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 145%;

  letter-spacing: -0.15px;

  color: #15284c;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.inline-group {
  display: inline-flex;
  flex-direction: row;
}

.success-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 22px;

  position: absolute;
  width: 384px;
  height: 290px;
  left: 528px;
  top: 64px;
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.success-header {
  width: 384px;
  height: 38px;


  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;

  text-align: center;
  letter-spacing: -0.7px;
}

.success-lorum {
  width: 383px;
  height: 52px;


  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 145%;

  text-align: center;
}

.error-alert {
  width: 384px;
  height: 42px;

  flex: none;
  order: 0;
  flex-grow: 0;
  position: relative;
}

.error-frame {
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 4px;

  position: relative;
  top: 0;
  width: 384px;
  height: 32px;

  mix-blend-mode: normal;
  background: rgb(77.25%, 10.98%, 10.98%, 0.15);
  border-radius: 4px;
}

.error-alert-text {
  width: 384px;
  height: 17px;
  left: 115px;
  top: 7.5px;
  gap: 4px;
  position: absolute;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;

  letter-spacing: -0.1px;


  color: #c51c1c;
  opacity: 1;
}

.error-image {
  position: absolute;
  right: 275px;
  top: 6.59px;
  height: 18.83px;
  width: 20px;
}
