form {
  padding-top: 80px;
  margin: 0px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  left: 528px;
  top: 180px;
  font-family: 'Lexend';
}

input {
  color: #15284c;
}

.input-container input:focus-within {
  border: 2px solid #15284c;
  margin: 0px;
  padding: 12px;
}

.input-container {
  font-family: 'Lexend';
  position: relative;
  display: flex;
  flex-direction: column;

  height: 88px;
  width: 384px;
  left: 0px;
  right: 0px;
  top: 7px;
  border-radius: 4px;
  color: #15284c;
}

.input-container .label-filled {
  transform: translate(-6px, -22px) scale(1);
  padding: 4px;
  max-width: fit-content;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  height: 14px;
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform-origin: top left;
  transition: 400ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-family: 'Lexend';

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 145%;

  color: rgb(21, 40, 76, 0.75);
  background-color: rgb(255, 255, 255, 1);

  height: 31px;
  left: 16px;
  top: 12px;
}

.text-area.form-xl-flat label {
  top: 25px !important;
  min-width: fit-content !important;
  height: 12px !important;
}

.text-area.form-xl-flat .label-filled {
  transform: translate(2px, -30px) scale(1) !important;
  padding: 4px;
  max-width: fit-content;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  height: 12px !important;
  background-color: rgb(255, 255, 255, 1) !important;
  color: rgb(21, 40, 76, 0.75);
  padding-bottom: 14px !important;
}

.text-area.form-xl-messaging label {
  top: 25px !important;
  min-width: fit-content !important;
  height: 12px !important;
}

.text-area.form-xl-messaging .label-filled {
  transform: translate(-10px, -28px) scale(1) !important;
  background-color: rgb(255, 255, 255, 1) !important;
  height: 12px !important;
}

.input-container input {
  height: 56px;
  border-radius: 4px;
  border: 1px solid #dedede;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  font-family: 'Lexend';
  font-weight: 300;
  padding: 13px;
}

.form-digit {
  width: 40px !important;
}

.form-s {
  width: 96px !important;
}

.form-m {
  width: 192px !important;
}

.form-l {
  width: 288px !important;
}

.form-xl {
  width: 384px !important;
}

.form-xl-flat {
  width: 384px;
  height: 70px !important;
}

.rounded-left {
  border-radius: 4px 0px 0px 4px !important;
}

.square-input {
  border-radius: 0px !important;
}

.rounded-right {
  border-radius: 0px 4px 4px 0px !important;
}

.assistive-text {
  position: relative;
  width: 330px;
  height: 18px;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 130%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 61px;
  gap: 4px;
  left: 27px;
  color: #c51c1c;
}

.assistive-image {
  position: relative;
  left: 10px;
  height: 15px;
  width: 14.12px;
  top: 6px;
}

.password-assistive-text {
  position: relative;
  height: 18px;
  opacity: 0.75;
  font-weight: 300;
  font-size: 13px;
  line-height: 130%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 61px;
  gap: 4px;
  left: 16px;
}

input.error {
  border: 2px solid #c51c1c;
}

label.error {
  color: #c51c1c;
}

.assistive-text .error {
  color: #c51c1c;
}

.input-container input.error:focus-within {
  border: 2px solid #c51c1c;
}

.text-area {
  width: 327px;
  height: 201px;
  border-radius: 4px;
  margin-bottom: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  left: 0px;
  right: 0px;
  top: 7px;
}

.text-area textarea:focus-within {
  border: 2px solid #15284c;
  margin: 0px;
  padding: 12px;
}

.text-area .t-area-error:focus-within {
  border: 2px solid #c51c1c;
}

.t-area {
  box-sizing: border-box;

  position: relative;
  left: 0px;
  right: 0px;
  top: 7px;
  bottom: 26px;

  background-color: transparent;
  resize: none;
  outline: none;
  font-family: 'Lexend';
  height: 168px;
  width: 384px;
  white-space: normal;

  border-radius: 4px;
  border: 1px solid #dedede;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  font-weight: 300;
  padding: 16px;
}

.t-area-error {
  box-sizing: border-box;

  position: relative;
  left: 0px;
  right: 0px;
  top: 7px;
  bottom: 26px;

  background-color: transparent;
  resize: none;
  outline: none;
  font-family: 'Lexend';
  height: 168px;
  width: 384px;

  border-radius: 4px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-weight: 300;
  padding: 16px;
  border: 2px solid #c51c1c;
}

.t-area.form-xl-flat {
  white-space: nowrap;
}

.text-area label {
  position: absolute;
  pointer-events: none;
  transform-origin: top left;
  transition: 400ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-family: 'Lexend';

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 145%;

  color: rgb(21, 40, 76, 0.75);
  background-color: rgb(255, 255, 255, 1);

  height: 31px;
  left: 16px;
  top: 12px;
  max-width: fit-content;
}

.text-area .label-filled {
  transform: translate(2px, -15px) scale(1);
  padding: 4px;
  max-width: fit-content;
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  height: 12px;
}

.t-area.area-bold {
  border: 2px solid #15284c;
}

.character-text {
  position: relative;
  width: 330px;
  height: 18px;
  bottom: -16px;
  left: 16px;
  font-size: 13px;
  font-weight: 300;
  line-height: 130%;
  opacity: 0.75;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.drop-down {
  box-sizing: border-box;
  position: absolute;
  height: 56px;
  left: 0px;
  right: 0px;
  border: 1px solid #dedede;
  border-radius: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:focus {
  outline: none !important;
}

.error {
  color: #c51c1c;
}

.text-area .error {
  color: #c51c1c;
}

label.label-filled.error {
  color: #c51c1c !important;
}

.drop-down-choice {
  padding: 12px 18px;
  position: relative;
  background: #ffffff;
  z-index: 1;
  width: 352px;
  height: 64px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
}

.drop-down-choice:hover {
  background: #f7f7f7;
}

.dropdown-list {
  position: relative;
  visibility: hidden;
  display: none;

  z-index: 1;
  border: 1px solid #fefefe;

  display: flex;
  flex-direction: column;
  top: 36px;
  transition: max-height 0.6s ease;
  border-radius: 4px;
}

.drop-down.open {
  border: 2px solid #15284c;
}

.drop-down.open .dropdown-list {
  visibility: visible;
  transition: max-height 0.6s ease;
}

.trailing-icon {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 20px;
  top: 20px;
  transition: transform 0.2s;
}

.open .trailing-icon {
  transform: rotate(180deg);
}

.dropdown-text {
  position: relative;
  top: 16px;
  left: 16px;
}

.file-uploader {
  position: relative;
  width: 384px;
  height: 256px;
}

.file-input-icon {
  width: 56px;
  height: 56px;
}

input[type='file'] {
  display: none;
  color: purple;
}

.photo-subtitle {
  width: 223px;
  height: 20px;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 145%;

  text-align: center;
}

.photo-upload-inner-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  position: relative;
  width: 143px;
  height: 84px;
  left: calc(50% - 143px / 2 + 0.5px);
  top: calc(50% - 84px / 2);
}

.upload-label {
  position: relative;
  display: block;
  width: 174px;
  height: 20px;
  left: 110px;
  top: 128px;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 145%;

  text-align: center;
  text-decoration-line: underline;
}

.input-label {
  width: 384px;
  height: 256px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block;
  cursor: pointer;
}

.dragged {
  background-color: red;
}

.file-input-selected-image {
  width: 383px;
  height: 256px;
  border-radius: 4px;
  animation: fadeIn 1.5s;
  object-fit: scale-down;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mini-input-label {
  width: 192px;
  height: 192px;
  border: 1px solid #ccc;
  border-radius: 4px;

  display: block;
  position: relative;
}

.mini-file-uploader {
  position: relative;
  width: 192px;
  height: 192px;
  object-fit: scale-down;
}

.mini-file-input-icon {
  position: absolute;
  width: 56px;
  height: 56px;
  bottom: 84px;
  left: 68.5px;
}

.mini-file-input-selected-image {
  position: relative;
  width: 192px;
  height: 192px;
  object-fit: cover;
  border-radius: 4px;
  animation: fadeIn 1.5s;
}

.mini-subtitle {
  position: relative;
  width: 101px;
  height: 20px;
  left: 46px;
  top: 116px;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
}

.rectangle-mini-file-input-icon {
  position: absolute;
  width: 56px;
  height: 56px;
  bottom: 46px;
  left: 62.5px;
}

.rectangle-mini-subtitle {
  position: relative;
  width: 101px;
  height: 20px;
  left: 42px;
  top: 82px;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
}

.mini-input-square {
  width: 192px !important;
  height: 192px !important;
}

.mini-input-rectangle {
  width: 180px !important;
  height: 120px !important;
}

.invalid-photo {
  border: 1px solid #c51c1c;
  border-radius: 4px;
}

.sucessful-photo-validation {
  border: 1px solid #117a00;
  border-radius: 4px;
}

.form-xl-messaging {
  width: 320px !important;
  height: 201px !important;
}
