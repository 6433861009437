body {
  display: absolute;
  margin: 0px;
}

.app {
  font-family: 'Lexend', Helvetica, sans-serif;
}

a {
  text-decoration: none;
}

.headline-1-desktop {
  font-size: 96px;
  font-weight: 'Medium', 500;
  letter-spacing: -2.5px;
  line-height: 105%;
}

.headline-1-mobile {
  font-size: 72px;
  font-weight: 'Medium', 500;
  letter-spacing: -2px;
  line-height: 105%;
}

.headline-2-desktop {
  font-size: 72px;
  font-weight: Medium, 500;
  letter-spacing: -2px;
  line-height: 105%;
}

.headline-2-mobile {
  font-size: 48px;
  font-weight: Medium, 500;
  letter-spacing: -2px;
  line-height: 105%;
}

.headline-3-desktop {
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 115%;
}

.headline-3-mobile {
  font-size: 36px;
  font-weight: Medium, 500;
  letter-spacing: -1px;
  line-height: 115%;
}

.headline-4-desktop {
  font-size: 32;
  font-weight: Medium, 500;
  letter-spacing: -0.7px;
  line-height: 120%;
}

.headline-4-mobile {
  font-size: 28;
  font-weight: Medium, 500;
  letter-spacing: -0.7px;
  line-height: 120%;
}

.headline-5 {
  font-size: 24;
  font-weight: Medium, 500;
  letter-spacing: -0.3px;
  line-height: 130%;
}

.headline-6 {
  font-size: 21;
  font-weight: Regular, 400;
  letter-spacing: -0.2px;
  line-height: 135%;
}

.body-1 {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 145%;
}

.body-2 {
  font-size: 16px;
  font-style: light;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 145%;
}

.body-3 {
  font-size: 14;
  font-weight: Light, 300;
  letter-spacing: 0px;
  line-height: 145%;
}

.assistive-text {
  font-size: 13;
  font-weight: Light, 300;
  letter-spacing: 0px;
  line-height: 130%;
}

p {
  margin-block-end: 0;
  margin-block-start: 0;
}
