.selected {
  opacity: 1;
  text-decoration: underline;
  text-underline-offset: 4px;
  transition: 30ms;
}

.label-text {
  font-size: 16px;
  line-height: 120%;
  color: #15284c;
  opacity: 0.75;
  letter-spacing: 0.1px;
  font-weight: 500;
}

.label-text:hover {
  font-size: 16px;
  line-height: 120%;
  color: #15284c;
  opacity: 1;
  letter-spacing: 0.1px;
  font-weight: 500;
}
