.photo-font {
  font-size: 14px;
  line-height: 21px;
  font-weight: 200;
}
.subtitle-photo {
  font-family: 'Lexend';
  font-size: 16px;
  line-height: 21px;
  font-style: normal;
}
.photo-list {
  text-decoration: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.details-main {
  width: 664px;
  padding: 24px;
}

.details-grey {
  width: 665px;
  padding: 24px;
  margin-top: 313px;
  border-bottom: 2px solid #979797;
}

.details-white {
  width: 665px;
  padding: 24px;
  margin-top: 313px;
}

.navy-box {
  background-color: #15284c;
  width: 315px;
  height: 200px;
  padding: 75.3px 65.3px;
}

.navy-box-brand {
  background-color: #15284c;
  width: 419px;
  height: 275px;
  padding: 25.3px 95.3px;
}

.border-dashed {
  border-style: dashed;
  color: #50e3c2;
}

.sideways-text {
  transform: rotate(90deg);
  height: 34px;
}

.imageHolder {
  position: relative;
  background: url('photo-area-bg.png');
  background-repeat: no-repeat;
  height: auto;
  max-width: 981px;
}

.imageHolder-full {
  position: relative;
  background: url('photo-area-bg.png');
  background-repeat: no-repeat;
  height: auto;
  width: 665px;
  height: 440px;
  background-size: 675px;
  background-position: 50% 50%;
}

.imageHolder-md {
  position: relative;
  background: url('photo-area-md.png');
  background-repeat: no-repeat;
  height: auto;
  width: 425px;
  height: 278px;
  border-radius: 10px;
}

.imageHolder-semi {
  position: relative;
  background: url('photo-area-semi.png');
  background-repeat: no-repeat;
  width: 384px;
  height: 218px;
  border-radius: 10px;
}

.imageHolder-semi-top {
  position: relative;
  background: url('photo-area-semi.png');
  background-repeat: no-repeat;
  width: 384px;
  height: 218px;
  border-radius: 10px;
}

.imageHolder-small {
  position: relative;
  background: url('photo-area-small.png');
  background-repeat: no-repeat;
  width: 107px;
  height: 107px;
  background-position: 25% 5%;
  border-radius: 10px;
}

.imageTea-full {
  position: relative;
  background: url('art-tea-full.png');
  background-repeat: no-repeat;
  height: auto;
  width: 665px;
  height: 440px;
  background-size: 675px;
  background-position: 50% 50%;
}

.imageTea-semi {
  position: relative;
  background: url('art-tea-semi.png');
  background-repeat: no-repeat;
  width: 384px;
  height: 218px;
  border-radius: 10px;
  background-size: 384px;
  background-position: 50% 50%;
}

.imageTea-small {
  position: relative;
  background: url('art-tea-small.png');
  background-repeat: no-repeat;
  width: 107px;
  height: 107px;
  background-size: 173px;
  background-position: 50% 50%;
  border-radius: 10px;
}

.imageYoga-full {
  position: relative;
  background: url('yoga-full.png');
  background-repeat: no-repeat;
  height: auto;
  width: 665px;
  height: 440px;
  background-size: 675px;
  background-position: 50% 50%;
}

.imageYoga-semi {
  position: relative;
  background: url('yoga-semi.png');
  background-repeat: no-repeat;
  width: 384px;
  height: 218px;
  border-radius: 10px;
  background-size: 384px;
  background-position: 50% 50%;
}

.imageYoga-small {
  position: relative;
  background: url('yoga-small.png');
  background-repeat: no-repeat;
  width: 107px;
  height: 107px;
  background-size: 173px;
  background-position: 50% 50%;
  border-radius: 10px;
}

.imageStudio-full {
  position: relative;
  background: url('studio-yoga-full.png');
  background-repeat: no-repeat;
  height: auto;
  width: 665px;
  height: 440px;
  background-size: 675px;
  background-position: 50% 50%;
}

.imageStudio-semi {
  position: relative;
  background: url('studio-yoga-semi.png');
  background-repeat: no-repeat;
  width: 384px;
  height: 218px;
  border-radius: 10px;
  background-size: 384px;
  background-position: 50% 50%;
}

.imageStudio-small {
  position: relative;
  background: url('studio-yoga-small.png');
  background-repeat: no-repeat;
  width: 107px;
  height: 107px;
  background-size: 173px;
  background-position: 50% 50%;
  border-radius: 10px;
}

.imageGreen-full {
  position: relative;
  background: url('green-yoga-full.png');
  background-repeat: no-repeat;
  height: auto;
  width: 665px;
  height: 440px;
  background-size: 675px;
  background-position: 50% 50%;
}

.imageGreen-semi {
  position: relative;
  background: url('green-yoga-semi.png');
  background-repeat: no-repeat;
  width: 384px;
  height: 218px;
  border-radius: 10px;
  background-size: 384px;
  background-position: 50% 50%;
}

.imageGreen-small {
  position: relative;
  background: url('green-yoga-small.png');
  background-repeat: no-repeat;
  width: 107px;
  height: 107px;
  background-size: 173px;
  background-position: 50% 50%;
  border-radius: 10px;
}

.imageCaudalie-full {
  position: relative;
  background: url('caudalie-full.png');
  background-repeat: no-repeat;
  height: auto;
  width: 665px;
  height: 440px;
  background-size: 675px;
  background-position: 50% 50%;
}

.imageCaudalie-semi {
  position: relative;
  background: url('caudalie-semi.png');
  background-repeat: no-repeat;
  width: 384px;
  height: 218px;
  border-radius: 10px;
  background-size: 384px;
  background-position: 50% 50%;
}

.imageCaudalie-small {
  position: relative;
  background: url('caudalie-small.png');
  background-repeat: no-repeat;
  width: 107px;
  height: 107px;
  background-size: 173px;
  background-position: 50% 50%;
  border-radius: 10px;
}

.imageCovid-full {
  position: relative;
  background: url('covid-full.png');
  background-repeat: no-repeat;
  height: auto;
  width: 665px;
  height: 440px;
  background-size: 675px;
  background-position: 50% 50%;
}

.imageCovid-semi {
  position: relative;
  background: url('covid-semi.png');
  background-repeat: no-repeat;
  width: 384px;
  height: 218px;
  border-radius: 10px;
  background-size: 384px;
  background-position: 50% 50%;
}

.imageCovid-small {
  position: relative;
  background: url('covid-small.png');
  background-repeat: no-repeat;
  width: 107px;
  height: 107px;
  background-size: 173px;
  background-position: 50% 50%;
  border-radius: 10px;
}

hr.grey-line {
  border-top: 2px solid #15284c;
}

.label-btn {
  width: 72px;
  height: 32px;
  color: #15284c;
  border-radius: 4px;
  background-color: #fff;
  line-height: 21px;
  font-size: 17px;
  margin-top: 10px;
  padding: 5px;
  font-weight: 400;
}

.label-btn-low {
  width: 72px;
  height: 32px;
  color: #15284c;
  border-radius: 4px;
  background-color: #fff;
  line-height: 21px;
  font-size: 17px;
  margin-top: 10px;
  padding: 5px;
  font-weight: 400;
  position: absolute;
  bottom: 40px;
  right: 42px;
}

.label-food-btn {
  width: 156px;
  height: 32px;
  color: #15284c;
  border-radius: 4px;
  background-color: #fff;
  line-height: 21px;
  font-size: 17px;
  margin-top: 10px;
  padding: 5px;
  font-weight: 400;
}

.label-food-btn-low {
  width: 156px;
  height: 32px;
  color: #15284c;
  border-radius: 4px;
  background-color: #fff;
  line-height: 21px;
  font-size: 17px;
  margin-top: 10px;
  padding: 5px;
  font-weight: 400;
  position: absolute;
  bottom: 40px;
  right: 42px;
}

.label-mind-btn {
  width: 112px;
  height: 32px;
  color: #15284c;
  border-radius: 4px;
  background-color: #fff;
  line-height: 21px;
  font-size: 17px;
  margin-top: 10px;
  padding: 5px;
  font-weight: 400;
}

.label-mind-btn-low {
  width: 112px;
  height: 32px;
  color: #15284c;
  border-radius: 4px;
  background-color: #fff;
  line-height: 21px;
  font-size: 17px;
  margin-top: 10px;
  padding: 5px;
  font-weight: 400;
  position: absolute;
  bottom: 40px;
  right: 42px;
}

.X-btn {
  margin-top: 47px;
  margin-left: 10px;
}

.photo-text {
  color: #fff;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  padding: 255px 328px;
}

.overlay-left {
  position: absolute;
  max-width: 125px;
  height: auto;
  top: 0;
  left: 0;

  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.overlay-right {
  position: absolute;
  max-width: 125px;
  height: auto;
  top: 0;

  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media only screen and (max-width: 1970px) {
  .details-main {
    width: 375px;
    padding: 24px;
  }

  .details-grey {
    width: 375px;
    padding: 24px;
    margin-top: 167px;
    border-bottom: 2px solid #979797;
  }

  .details-white {
    width: 375px;
    padding: 24px;
    margin-top: 167px;
  }

  .imageHolder-full {
    width: 375px;
    height: 294px;
    background-size: 475px;
  }

  .imageHolder-semi {
    position: relative;
    background: url('photo-area-semi.png');
    background-repeat: no-repeat;
    width: 290px;
    height: 218px;
    border-radius: 10px;
  }

  .imageTea-full {
    width: 375px;
    height: 294px;
    background-size: 475px;
  }

  .imageTea-semi {
    position: relative;
    background: url('art-tea-semi.png');
    background-repeat: no-repeat;
    width: 290px;
    height: 218px;
    border-radius: 10px;
    background-size: 384px;
    background-position: 50% 50%;
  }

  .imageYoga-full {
    width: 375px;
    height: 294px;
  }

  .imageYoga-semi {
    position: relative;
    background: url('yoga-semi.png');
    background-repeat: no-repeat;
    width: 290px;
    height: 218px;
    border-radius: 10px;
    background-size: 384px;
    background-position: 50% 50%;
  }

  .imageStudio-full {
    width: 375px;
    height: 294px;
  }

  .imageStudio-semi {
    position: relative;
    background: url('studio-yoga-semi.png');
    background-repeat: no-repeat;
    width: 290px;
    height: 218px;
    border-radius: 10px;
    background-size: 384px;
    background-position: 50% 50%;
  }

  .imageGreen-full {
    width: 375px;
    height: 294px;
  }

  .imageGreen-semi {
    position: relative;
    background: url('green-yoga-semi.png');
    background-repeat: no-repeat;
    width: 290px;
    height: 218px;
    border-radius: 10px;
    background-size: 384px;
    background-position: 50% 50%;
  }

  .imageCaudalie-full {
    width: 375px;
    height: 294px;
  }

  .imageCaudalie-semi {
    position: relative;
    background: url('caudalie-semi.png');
    background-repeat: no-repeat;
    width: 290px;
    height: 218px;
    border-radius: 10px;
    background-size: 384px;
    background-position: 50% 50%;
  }

  .imageCovid-full {
    width: 375px;
    height: 294px;
  }

  .imageCovid-semi {
    position: relative;
    background: url('covid-semi.png');
    background-repeat: no-repeat;
    width: 290px;
    height: 218px;
    border-radius: 10px;
    background-size: 384px;
    background-position: 50% 50%;
  }
}

@media only screen and (max-width: 1600px) {
  .col-left-margin {
    margin-left: 90px;
  }
}

.image-requirements-title {
  font-size: 30pt;
  line-height: 36pt;
  letter-spacing: -0.66pt;
}

.image-requirements-subtitle {
  font-size: 21pt;
  line-height: 28pt;
  letter-spacing: -0.3pt;
}

.image-requirements-sub-subtitle {
  font-size: 16pt;
  line-height: 24pt;
  letter-spacing: -0.1pt;
}
