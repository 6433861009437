.button {
  border-radius: 28px;
  padding: 20px 31px;
  align-items: center;
  line-height: 105%;
  display: inline-flex;
  justify-content: center;
  letter-spacing: -0.1px;
  transition: 0.3s;
  height: 56px;
  cursor: pointer;
  font-size: 16px;

  align-items: center;
  text-align: center;
}

.label {
  height: 17px;
  min-width: max-content;
  font-weight: 700;

  font-size: inherit;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: inherit;
  line-height: 105%;

  display: flex;
  letter-spacing: -0.1px;

  align-items: center;
  text-align: center;
}

.blue {
  background: #15284c;
  color: #ffffff;
  border: solid 2.5px #15284c;
}

.baby-blue {
  background: #a8d9ff;
  color: #15284c;
  border: 2.5px solid #a8d9ff;
}

.blue-baby-blue {
  background: #15284c;
  color: #a8d9ff;
  border: 2.5px solid #15284c;
}

.white-navy {
  background: white;
  color: #15284c;
  border: 2.5px solid #15284c;
}

.all-white {
  background: white;
  color: #15284c;
  border: 2.5px solid white;
}

.small {
  height: 36px;
  padding: 0px 14px;
  font-size: 14px;
  font-weight: 400;
}

.disabled-button {
  background: darkgray;
  color: white;
  border: 2.5px solid darkgray;
}

.filter-button {
  background-color: white;
  color: #15284c;
  border: 1px solid #dedede;
}

.filter-button-hover {
  background-color: #f7f7f7;
  border: 1px solid #dedede;
  color: #15284c;
}

.filter-button-selected {
  background-color: white;
  border: 2px solid #15284c;
  color: #15284c;
}

.f-button {
  border-radius: 22px;
  padding: 12px 16px 12px 16px;
  align-items: center;
  line-height: 105%;
  display: inline-flex;
  justify-content: center;
  letter-spacing: -0.1px;
  transition: 0.1s;
  height: 44px;
  width: 73px;
  cursor: pointer;
  font-size: 16px;

  align-items: center;
  text-align: center;
}
