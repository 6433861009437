// Include all of Bootstrap
@import '~bootstrap/scss/_functions';

// Custom.scss

// Settings for the `<body>` element.

$body-color: #15284c !default;

// Updates

// h fonts
$h1-font-size: 6rem;
$h2-font-size: 4rem;
$h3-font-size: 3rem;
$h4-font-size: 2rem;
$h5-font-size: 1.5rem;
$h6-font-size: 1.313rem;
$h7-font-size: 1.75rem;

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5rem,
  2: 4rem,
  3: 3rem,
  4: 2rem,
  5: 1.5rem,
  6: 1.313rem,
  7: 1.75rem,
);

// Override default variables before the import
// you can open the bootstrap.scss module and check any available variables.
$primary: #15284c;
$secondary: #f0f7ff;
$light: #f7f7f7;

// scss-docs-start gray-color-variables
$white: #fff;

// link style
$link-decoration: none;

// scss-docs-start headings-variables
$headings-font-family: 'Lexend';
$headings-font-style: normal;
$headings-font-weight: 500;
$headings-line-height: 1.05;
// $headings-margin-top:        $spacer * .5;

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 1.5rem,
  6: 1.313rem,
  7: 1.75rem,
);

$display-font-family: 'Lexend';

$display-font-weight: 400;
$display-line-height: 1.35;

// special borders

.border-dashed {
  border-style: dashed;
}

// titles

.title-block {
  padding-left: 234px;
  padding-right: 234px;
  padding-top: 150px;
  height: 700px;
}

.sub-title {
  font-family: 'Lexend';
  font-weight: 400;
  font-size: 21px;
  letter-spacing: -0.2px;
  font-style: normal;
  text-align: center;
}

.subtitle {
  font-family: 'Lexend';
  font-size: 18px;
  line-height: 21px;
  font-style: normal;
}

// landing quote

.quote-block {
  width: 592px;
}

//h6 line-height

h6,
.h6 {
  line-height: 1.35;
}

h2,
.h2 {
  letter-spacing: -2px;
  font-size: 4rem;
}

.lh-sm {
  line-height: 28.35px;
}

.lh-lg {
  line-height: 32px;
}

// remove end

// scss-docs-end display-headings

$font-family-sans-serif: 'Lexend', system-ui, -apple-system, 'Segoe UI', Roboto,
  'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// Buttons

$btn-font-size-sm: 1.17rem !important;
$font-size-sm: 1.17rem !important;

small,
.small {
  font-size: 0.875 !important;
}

.submit-btn-contact {
  position: relative;
  left: 140px;
  top: 54px;
}

//sections removed

.removed {
  display: none !important;
}

// testimonial

.testimonial {
  display: inline-block;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin: 0px 16px;
  gap: 16px;

  width: 280px;
  height: 185px;
}

.testimonial-box {
  margin-right: 32px;
}

.testimonial-picture {
  width: 88px;
  height: 88px;
  border-radius: 50px;
  background-color: #dedede;
}

.testimonial-title {
  width: 280px;
  height: 31px;

  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  text-align: center;
  letter-spacing: -0.3px;
}

.frame-11 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 280px;
  height: 81px;
}

.testimonial-text {
  width: 280px;
  /* height: 46px; */

  /* Desktop/Body 2 */

  font-weight: 300;
  font-size: 16px;
  line-height: 145%;
  /* or 23px */

  text-align: center;
}

//footer bg color

.bg-footer {
  //--bs-bg-opacity: 1;
  background-color: #f0f7ff;
}

// Default variable overrides
$body-bg: #fff;

// Gutters
.grid-center {
  // $grid-gutter-width: 7.5rem !important;
  --bs-gutter-x: 7.5rem !important;
}

.grid-center2 {
  // $grid-gutter-width: 1.5rem !important;
  --bs-gutter-x: 1.5rem !important;
}

// align-items

.align-sec {
  align-items: center;
}

//custom landing page divs

.partner-shadow {
  background: #dedede;
  border-radius: 8px;
  height: 92px;
  width: 176px;
}

.rectangle-9 {
  width: 593px;
  height: 593px;
  background-color: #f0f7ff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.landing-page {
  /* position: absolute; */
  height: auto;
  left: 0%;
  right: 0%;
  top: 64px;
  overflow: hidden;
}

.hero {
  position: relative;
  height: 700px;
  left: 0px;
  right: 0px;
  background-color: #f0f7ff;
  color: #15284c;
  display: inline-block;
  width: 100%;
  padding: 155px 130px 344px 112px;
  padding-left: 112px !important;
  padding-right: 130px !important;
}

.headline-3-desktop {
  font-size: 3vw;
}

.nav-bar {
  height: 64px !important;
}

.nav-space {
  padding-top: 16.5px;
  padding-bottom: 14.5px;
}

.nav-buttons {
  padding-top: 3px;
  gap: 48px;
}

.login-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 16px;

  position: absolute;
  width: 201px;
  height: 36px;
  right: 40px;
  top: calc(50% - 36px / 2);
}

.shadow-sm2 {
  box-shadow: 0 0.285rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.business {
  position: absolute;
  width: 70px;
  height: 14px;
  left: 234px;
  top: 27px;

  color: #384867;

  font-family: 'Lexend';

  /* font-style:italic; */
  font-weight: 300;
  font-size: 12px;
  line-height: 14.4px;
  /* or 14px */
}

// large top margin and padding

.top-19 {
  margin-top: 19px;
}

.bottom-19 {
  margin-bottom: 19px;
}

.top-52 {
  margin-top: 52px;
}

.bottom-55 {
  margin-bottom: 55.2px;
}

.right-32 {
  margin-right: 32px;
}

.top-62 {
  padding-top: 62.23px;
}

.top-64 {
  padding-top: 64px;
}

.top-80 {
  padding-top: 80px;
}

.top-m-80 {
  margin-top: 80px;
}

.top-96 {
  padding-top: 96.5px;
}

.top-136 {
  padding-top: 136px;
}

.top-203 {
  margin-top: 203.5px;
}

// bottom margin

.bottom-90 {
  margin-bottom: 90px !important;
}

// footer

.footer-image {
  max-width: 203px;
  height: auto;
}

.landing-title {
  font-family: 'Lexend';
  color: #15284c;
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 105%;
  /* or 67px */

  text-align: center;
  letter-spacing: -2px;
}

.section-title {
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 115%;
  /* or 55px */

  text-align: center;
  letter-spacing: -1px;
}

// letterspace

.letter-s1 {
  letter-spacing: -2px !important;
}

.letter-s2 {
  letter-spacing: -0.2px;
}

// slideshow

.slideshow-container-welcome {
  position: absolute;
  width: 969.51px;
  height: 700px;
  left: 0px;
  top: 64px;
}

.slideshow-container {
  position: absolute;
  width: 969.51px;
  height: 700px;
  right: 0px;
  top: 64px;
}

.slideshow-1 {
  background: url('slideshow1.svg');
  /* margin-left:850px; */
  width: 969.51px;
  height: 700px;
  transition: background-image 0.23s ease-in-out;
}
.slideshow-2 {
  background: url('slideshow2.svg');
  /* margin-left:850px; */
  width: 969.51px;
  height: 700px;
  transition: background-image 0.23s ease-in-out;
}
.slideshow-3 {
  background: url('slideshow3.svg');
  /* margin-left:850px; */
  width: 969.51px;
  height: 700px;
  transition: background-image 0.23s ease-in-out;
}

@media only screen and (max-width: 1700px) {
  // slideshow

  .slideshow-container-welcome {
    position: absolute;
    width: 720px;
    height: 700px;
    left: -200px;
    top: 54px;
  }

  .slideshow-container {
    position: relative;
    max-width: 720px;
    height: auto;
    right: 0px;
    top: 0px;
  }

  .title-block {
    padding-left: 114px;
    padding-right: 114px;
    padding-top: 155px;
    height: 700px;
  }
}

@media only screen and (max-width: 1440px) {
  .title-block {
    padding-left: 114px;
    padding-right: 114px;
    padding-top: 155px;
    height: 700px;
  }

  .sub-title {
    font-family: 'Lexend';
    font-weight: 400;
    font-size: 21px;
    letter-spacing: -0.2px;
    font-style: normal;
    text-align: center;
    line-height: 135%;
  }
}

@media only screen and (max-width: 1301px) {
  // slideshow

  .slideshow-container-welcome {
    position: relative;
    width: 720px;
    height: 700px;
    left: -362px;
    top: 0px;
  }

  .slideshow-container {
    position: relative;
    width: 720px;
    height: 700px;
    right: 0px;
    top: 0px;
  }
}

@media only screen and (max-width: 1320px) {
  .grid-center2 {
    // $grid-gutter-width: 1.5rem !important;
    --bs-gutter-x: 0rem !important;
    text-align: center;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 998px) {
  .title-block {
    padding-left: 54px;
    padding-right: 54px;
    padding-top: 57px;
    height: 700px;
    width: 520px;
  }

  .slideshow-container-welcome {
    position: relative;
    width: 720px;
    height: 700px;
    left: -362px;
    top: 0px;
  }

  .slideshow-container {
    position: absolute;
    width: 700px;
    height: 700px;
    right: -169px;
    top: 64px;
  }

  .title-block {
    padding-left: 54px;
    padding-right: 54px;
    padding-top: 57px;
  }
}

// tablet

@media only screen and (max-width: 990px) {
  .title-block {
    padding-left: 67px;
    padding-right: 67px;
    padding-top: 154px;
    height: 409px;
  }

  .height-push {
    padding-bottom: 87px;
  }

  .slideshow-container-welcome {
    position: relative;
    width: 720px;
    height: 700px;
    left: 0px;
    top: 0px;
  }

  .slideshow-container {
    position: relative;
    width: 720px;
    height: 700px;
    right: 20px;
    top: 0px;
  }

  @media only screen and (max-width: 735px) {
    .height-push {
      padding-bottom: 184px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .nav-bar {
    height: 94px !important;
  }

  .hero {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 53.83%;
  }

  .height-push {
    padding-bottom: 184px;
  }

  .business {
    display: none;
  }

  // slideshow

  .slideshow-container-welcome {
    position: absolute;
    width: 720px;
    height: 700px;
    left: 0px;
    top: 64px;
  }

  .slideshow-container {
    position: relative;
    width: 504.9px;
    height: 365px;
    right: 17px;
    top: 0px;
  }

  // hamburger

  body {
    margin: 0;
    padding: 0;

    /* make it look decent enough */
    background: #232323;
    color: #cdcdcd;
    font-family: 'Avenir Next', 'Avenir', sans-serif;
  }

  #menuToggle {
    display: block;
    position: relative;
    top: 17px;
    left: 91px;

    z-index: 1;

    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
  }

  #menuToggle a:hover {
    color: tomato;
  }

  #menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  /*
 * Just a quick hamburger
 */
  #menuToggle span {
    display: block;
    width: 30px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;
    background: #15284c;

    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
  }

  /*
 * But let's hide the middle one.
 */
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
 * Ohyeah and the last one should go the other direction
 */
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  /*
 * Make this absolute positioned
 * at the top left of the screen
 */
  #menu {
    position: absolute;
    width: 600px;
    margin: 40px 0 0 177px;
    padding: 10px;
    padding-top: 0px;

    background: #fff;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, -150%);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menu li {
    padding: 16px 30px;
    font-size: 16px;
    text-align: left;
  }

  /*
 * And let's slide it in from the left
 */
  #menuToggle input:checked ~ ul {
    transform: translate(-100%, 0);
  }

  // end hamburger
}

@media only screen and (max-width: 500px) {
  .nav-bar {
    height: 94px !important;
  }

  .hero {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 53.83%;
  }

  .height-push {
    padding-bottom: 294px;
  }

  // slideshow

  .slideshow-container-welcome {
    position: absolute;
    width: 504.9px;
    height: 365px;
    left: 0px;
    top: 64px;
  }

  .slideshow-container {
    position: relative;
    width: 504.9px;
    height: 365px;
    right: 17px;
    top: 0px;
  }

  .slideshow-1 {
    background: url('slideshow-mobile1.svg');
    /* margin-left:850px; */
    width: 504.95px;
    height: 365px;
    transition: background-image 0.23s ease-in-out;
  }
  .slideshow-2 {
    background: url('slideshow-mobile2.svg');
    /* margin-left:850px; */
    width: 504.95px;
    height: 365px;
    transition: background-image 0.23s ease-in-out;
  }
  .slideshow-3 {
    background: url('slideshow-mobile3.svg');
    /* margin-left:850px; */
    width: 504.95px;
    height: 365px;
    transition: background-image 0.23s ease-in-out;
  }

  .title-block {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0px;
    height: 240px;
  }

  .rectangle-9 {
    width: 593px;
    height: 409px;
    background-color: #f0f7ff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .business {
    display: none;
  }

  .login-buttons {
    display: none;
  }

  #menu {
    position: absolute;
    width: 500px;
    margin: 40px 0 0 120px;
    padding: 10px;
    padding-top: 0px;

    background: #fff;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, -150%);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
}

// Include all of Bootstrap

//@import "../node_modules/bootstrap/scss/bootstrap";
@import '~bootstrap/scss/bootstrap.scss';
