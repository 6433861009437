.right-form {
  position: absolute;
  height: 1516px;
  left: 22.22%;
  right: 0%;
  top: 64px;
}

.right-form-box {
  position: absolute;
  height: 1516px;
  left: 22.22%;
  right: 0%;
  top: 64px;
}

.nested-right-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 48px;

  position: absolute;
  width: 384px;
  height: 416px;
  left: 119px;
}

.business-form-headline {
  width: 384px;

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;

  letter-spacing: -0.7px;
}

.business-form-subtitle {
  width: 384px;


  font-family: 'Lexend';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 145%;

  letter-spacing: -0.15px;
}

.selection-boxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 384px;
  height: 224px;
}

.photo-subtitle-label {
  width: 384px;


  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 145%;


  color: #15284c;
}

.merchandise-photo-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 382px;
  height: 192px;


  flex: none;
  order: 0;
  flex-grow: 0;
}

.merchandise-photo-explainer {
  width: 174px;
  height: 40px;


  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 145%;


  color: #15284c;


  flex: none;
  order: 1;
  flex-grow: 0;
}

.bump-left {
  margin-left: 0px;
}

.success-subtitle {

  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 145%;

  text-align: center;


  color: #15284c;

}

.business-centered-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 40px;

  position: absolute;
  width: 384px;
  height: 346px;
  left: 528px;
  top: 64px;
}

.service-theme-box {
  position: absolute;
  width: 384px;
  height: 370px;
  left: 368px;
  top: 64px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 48px;
}

.theme-inner-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 48px;

  position: relative;
  width: 384px;
  height: 370px;
}

.theme-form {

  width: 384px;
  height: 284px;
  margin: 0px !important;
}

.multi-select-container {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.privacy-policy {
  width: 352px;
  height: 50px;
  position: relative;
  top: -35px;
  left: 5px;
  margin-bottom: -25px;

  font-weight: 300;
  font-size: 13px;
  line-height: 130%;

  display: flex;
  align-items: center;

  opacity: 0.75;
}

.privacy-policy a {
  text-decoration: underline;
}

.cancellation-assistive-text {
  width: 384px;
  height: 69px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 145%;
}

@media only screen and (max-width: 625px) {
  .right-form {
    position: absolute;
    height: 1516px;
    left: 0%;
    right: 97%;
    top: 64px;
  }
  .bump-left {
    margin-left: 90px;
  }

  .business-form-headline,
  .business-form-subtitle {
    width: 380px;
  }
}
