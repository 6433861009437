.policy-text {
  font-size: 18px;
  color: #000;
  padding-top: 10px;
  padding-bottom: 10px;
}

.policy-footer {
  background-color: #fff;
  color: #15284c;
  padding: 20px;
  margin-top: 100px;
}

.policy-list {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
}
