.navbar-menu-modal {
  box-sizing: border-box;
  position: fixed;
  width: 200px;
  right: 40px;
  top: 50px;


  background: #ffffff;

  border: 0.5px solid rgba(0, 0, 0, 0.1);

  box-shadow: 0px 25px 37px rgba(0, 0, 0, 0.1),
    0px 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  gap: 8px;
}

.minicard-menu-modal {
  box-sizing: border-box;
  flex-direction: column;
  box-shadow: 0px 25px 37px rgba(0, 0, 0, 0.1),
    0px 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  position: absolute;
  display: flex;
  align-items: flex-start;
  padding: 8px 0px;
  gap: 8px;
  background: #ffffff;

  border: 0.5px solid rgba(0, 0, 0, 0.1);
  right: 18px;
  margin-top: -25px;
  z-index: 999;
}

.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  cursor: pointer;
  width: 100%;
}

.menu-item:hover {
  background-color: #d4e8f6;
}

.menu-item-label {
  cursor: pointer;
  height: 23px;


  font-family: 'Lexend';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 145%;
}
